<template>
  <div>
    {{ bookingCategory }}
  </div>
</template>
<script>

export default {
  props: {
    item: {
      type: Object,
      default: () => {
      },
    }
  },
  computed: {
    bookingCategory() {
      if (this.item.category === null) {
        if (this.item.activityType === 'lesson') {
          return this.$t('views.bookings.index.type.lesson');
        } else if (this.item.activityType === 'sport' || this.item.activityType === 'leisure') {
          return this.$t('views.bookings.index.type.reservation');
        } else if (this.item.activityType === 'formula') {
          return this.$t('views.bookings.index.type.formula');
        }
      } else {
        return this.item.category.name;
      }
    },
  }
}
</script>
